<template>
  <div class="nav-bar">
    <div class="logo-container" @click="to('/')"></div>
    <div class="links-container">
      <div class="nav-link" @click="to('/')">首页</div>
      <div class="nav-link" @click="openLink('https://www.yuanzihaoxue.com/')">成人高考</div>
      <div class="nav-link" @click="openLink('https://www.yuanzihaoxue.com/')">职业技能</div>
      <div class="nav-link" @click="openLink('https://www.yuanzihaoxue.com/')">在职研究生</div>
      <div class="nav-link" @click="openLink('http://edu.yuanzihaoxue.com/')">在线教育</div>
      <div class="nav-link" @click="to('/about')">关于我们</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavCompPc',
  methods: {
    to (path) {
      if (this.$route.path === path) return
      this.$router.push({ path })
    },
    openLink (path) {
      window.open(path)
    }
  }
}
</script>

<style scoped>

.nav-bar {
  height: 80px;
  background-color: #252525;
  display: flex;
  justify-content: space-between;
}

.logo-container {
  background-image: url('//s3.bmp.ovh/imgs/2022/03/30/58fcbfc2403ee7c2.png');
  background-size: cover;
  height: 80px;
  width: 240px;
  margin-left: 88px;
  cursor: pointer;
}

.links-container {
  display: flex;
  justify-content: space-between;
  margin-right: 88px;
}

.nav-link {
  color: #ffffff;
  line-height: 80px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 24px;
  user-select: none;
}

.nav-link:hover {
  text-decoration: underline;
}

</style>
