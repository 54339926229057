<template>
  <div class="footer-container">
    <div class="footer-info">安徽蚌大教育咨询有限公司</div>
    <div class="footer-info">地址：安徽省蚌埠市凤凰国际B座709</div>
    <div class="footer-info">联系电话：19155203050</div>
    <div class="footer-info icp-info" @click="toGov()">皖ICP备2022004394号-1</div>
  </div>
</template>

<script>
export default {
  name: 'FooterComp',
  methods: {
    toGov () {
      window.open('https://ahca.miit.gov.cn/')
    }
  }
}
</script>

<style scoped>

.footer-container {
  background-color: #2f333f;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.footer-info {
  font-size: 12px;
  color: #888888;
  line-height: 20px;
}

.icp-info {
  cursor: pointer;
}

</style>
