<template>
  <div style="min-width: 1000px;">
    <nav-comp/>
    <el-scrollbar class="main-page-container">
      <router-view/>
      <footer-comp/>
    </el-scrollbar>
  </div>
</template>

<script>
import NavComp from '@/components/NavComp'
import FooterComp from '@/components/FooterComp'

export default {
  name: 'AppLayoutPc',
  components: { NavComp, FooterComp }
}
</script>

<style scoped>

.main-page-container {
  height: calc(100vh - 80px);
}

.main-page-container >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

</style>
