<template>
  <div>
    <app-layout-pc v-if="$pc"/>
    <app-layout-mobile v-else/>
  </div>
</template>

<script>
import AppLayoutPc from '@/views/layout/LayoutPc'
import AppLayoutMobile from '@/views/layout/LayoutMobile'

export default {
  name: 'AppLayout',
  components: { AppLayoutMobile, AppLayoutPc }
}
</script>

<style scoped>

</style>
