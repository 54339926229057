<template>
  <div>
    <div class="nav-bar" @click="linksShow = false">
      <div class="logo-container" @click="to('/')"></div>
      <div class="link-icon-container" @click.stop="linksShow = !linksShow">
        <i class="link-icon el-icon-s-unfold"/>
      </div>
    </div>
    <div class="links-container" v-show="linksShow">
      <div class="nav-link" @click="to('/')">首页</div>
      <div class="nav-link" @click="openLink('https://www.yuanzihaoxue.com/')">成人高考</div>
      <div class="nav-link" @click="openLink('https://www.yuanzihaoxue.com/')">职业技能</div>
      <div class="nav-link" @click="openLink('https://www.yuanzihaoxue.com/')">在职研究生</div>
      <div class="nav-link" @click="openLink('http://edu.yuanzihaoxue.com/')">在线教育</div>
      <div class="nav-link" @click="to('/about')">关于我们</div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'NavCompMobile',
  data () {
    return {
      linksShow: false
    }
  },
  methods: {
    to (path) {
      this.linksShow = false
      if (this.$route.path === path) return
      this.$router.push({ path })
    },
    openLink (path) {
      window.open(path)
    }
  }
}
</script>

<style scoped>

.nav-bar {
  height: 50px;
  background-color: #252525;
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.logo-container {
  background-image: url('//s3.bmp.ovh/imgs/2022/03/30/58fcbfc2403ee7c2.png');
  background-size: cover;
  height: 40px;
  margin-top: 5px;
  width: 120px;
  cursor: pointer;
}

.link-icon-container {
  padding: 0 14px;
}

.link-icon-container:hover {
  padding: 0 14px;
  background-color: #333333;
}

.link-icon {
  color: #ffffff;
  font-size: 24px;
  line-height: 50px;
}

.links-container {
  position: absolute;
  top: 50px;
  right: 0;
  width: 100px;
  z-index: 99999;
}

.nav-link {
  color: #ffffff;
  background-color: #2f333f;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  border-top: 1px solid #666666;
  padding: 0 20px;
}

.nav-link:hover {
  text-decoration: underline;
  background-color: #434e5f;
}

</style>
