<template>
  <div>
    <nav-comp-pc v-if="$pc"/>
    <nav-comp-mobile ref="mbNav" v-else/>
  </div>

</template>

<script>
import NavCompPc from '@/components/NavComp/NavCompPc'
import NavCompMobile from '@/components/NavComp/NavCompMobile'

export default {
  name: 'NavComp',
  components: { NavCompMobile, NavCompPc },
  methods: {
    closeMbNavLinks () {
      if (this.$pc) return
      this.$refs.mbNav.linksShow = false
    }
  }
}
</script>

<style scoped>

</style>
