import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { Scrollbar, Form, FormItem, RadioGroup, Radio, Input, Button, Message } from 'element-ui'

Vue.config.productionTip = false

Vue.use(Scrollbar)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Input)
Vue.use(Button)

Vue.prototype.$pc = (() => {
  const userAgentInfo = navigator.userAgent
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
})()

// 成功消息
Vue.prototype.msgSuccess = function (msg) {
  Message({
    showClose: true,
    message: msg,
    type: 'success'
  })
}

// 警告消息
Vue.prototype.msgWarn = function (msg) {
  Message({
    showClose: true,
    message: msg,
    type: 'warning'
  })
}

// 错误消息
Vue.prototype.msgError = function (msg) {
  Message({
    showClose: true,
    message: msg.replace('Error: ', ''),
    type: 'error'
  })
}

// 普通消息
Vue.prototype.msgInfo = function (msg) {
  Message.info(msg)
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
